/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import update from "immutability-helper";
import { validated } from "react-custom-validation";

import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, masterheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  showPriceValue,
  encodeValue,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
var moduleLink = "/masterpanel/commission/";
class Clientform extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/masterpanel/commission/adddebit/:EditID") {
      editID = this.props.match.params.EditID;
    }
    this.state = {
      editID: editID,
      pageloading: false,
      clientdata: {
        debitclentID: "",
        debitAmount: "",
        balanceAmount: 0,
        company_name: "",
        action: "add",
      },
      loading: true,
      checked: true,
      companyyList: [],
      formpost: [],
      companyDetail: [],
    };
  }
  componentDidMount() {
    this.companyList();

    this.loaDetails();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success");
          this.props.history.push("/masterpanel/commission");
        } else {
          showAlert("Error", errMsg, "error");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var clientupdatedata = {
            debitclentID: {
              label: result.company_name,
              value: result.company_id,
            },
            debitAmount: "",
            balanceAmount: result.company_commission_balance,
            company_name: result.company_name,
            action: "edit",
          };
          this.setState({ clientdata: clientupdatedata, pageloading: false });
        } else {
          showAlert("Error", "Invalid Company", "error");
        }
      });
    }
  }
  loaDetails() {
    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + this.state.editID,
        url: apiUrl + "company/companycontroller/company_details",
        type: "master",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }

  companyList() {
    var urlShringTxt = apiUrl + "company/companycontroller/companydropdown";

    axios.get(urlShringTxt, masterheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ companyyList: res.data.result });
      }
    });
  }
  sateValChange = (field, value) => {
    if (field === "page") {
    }
  };

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { clientdata: { [field]: { $set: value } } }),
      () => {
        if (field === "debitclentID") {
          this.setState({ editID: encodeValue(value.value) }, () => {
            this.loaDetails();
          });
        }
      }
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = {
      clientID: this.state.editID,
      debitAmount: this.state.clientdata.debitAmount,
      loginID: userID(),
    };
    var post_url = "company/companycontroller/adddebit";
    this.props.getFormPost(postData, post_url, "master");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"commission"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">Add Debit</h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={moduleLink}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <Form
                  {...this.props}
                  fields={this.state.clientdata}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  companyyList={this.state.companyyList}
                  onInvalid={() => {
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Clientform);

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const ischkcingAmount = function (value, debitAmount) {
  if (value !== null && debitAmount !== "") {
    return parseFloat(value) <= parseFloat(debitAmount)
      ? null
      : "Maximum " + showPriceValue(debitAmount) + " only debit";
  }
};

function validationConfig(props) {
  const { debitclentID, debitAmount, balanceAmount } = props.fields;

  return {
    fields: ["debitclentID", "debitAmount", "balanceAmount"],
    validations: {
      debitclentID: [[isEmpty, debitclentID]],
      debitAmount: [
        [isEmpty, debitAmount],
        [ischkcingAmount, debitAmount, balanceAmount],
      ],
    },
  };
}

class Form extends Component {
  constructor(props) {
    super(props);
  }

  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgdebitclentID,
      errMsgdebitAmount = "";
    if ($validation.debitclentID.error.reason !== undefined) {
      errMsgdebitclentID = $validation.debitclentID.show && (
        <span className="error">{$validation.debitclentID.error.reason}</span>
      );
    }

    if ($validation.debitAmount.error.reason !== undefined) {
      errMsgdebitAmount = $validation.debitAmount.show && (
        <span className="error">{$validation.debitAmount.error.reason}</span>
      );
    }

    return (
      <form className="card-body fv-plugins-bootstrap5" id="modulefrm">
        <div
          className="accordion mt-3 accordion-header-primary"
          id="accordionStyle1"
        >
          <div className="accordion-item active">
            <div
              id="accordionStyle1-3"
              className="accordion-collapse collapse show pt-5"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div
                    className={
                      errMsgdebitclentID !== "" &&
                      errMsgdebitclentID !== false &&
                      errMsgdebitclentID !== undefined
                        ? "col-md-6 error-select error"
                        : "col-md-6"
                    }
                  >
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.debitclentID}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "debitclentID"
                        )}
                        placeholder="Select Company"
                        options={this.props.companyyList}
                      />
                      <label className="select-box-label">
                        Client<span className="error">*</span>
                      </label>
                      {errMsgdebitclentID}
                    </div>
                    <b>Balance Amount : </b>
                    {showPriceValue(fields.balanceAmount)}
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className={
                          errMsgdebitAmount !== "" &&
                          errMsgdebitAmount !== false &&
                          errMsgdebitAmount !== undefined
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="debitAmount"
                        value={fields.debitAmount}
                        {...$field("debitAmount", (e) =>
                          onChange("debitAmount", e.target.value)
                        )}
                      />
                      <label htmlFor="debitAmount">
                        Debit Amount <span className="error">*</span>
                      </label>
                      {errMsgdebitAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 text-end">
          <button
            type="button"
            className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
            onClick={(e) => {
              e.preventDefault();
              this.props.$submit(onValid, onInvalid);
            }}
          >
            Submit
          </button>
          <Link to={moduleLink}>
            <button
              type="reset"
              className="btn btn-label-secondary waves-effect"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    );
  }
}
Form = validated(validationConfig)(Form);
