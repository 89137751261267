/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import { CompanyID, showPriceValue } from "../../Helpers/SettingHelper";

import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalCustomer: 0,
      currentMonthTopup: 0,
      totalTopup: 0,
      totalBuyVoucher: 0,
      totalSubscription: 0,
      topupchartData: [],
      subscribtionoptions: {},
      subscribtionseries: [],
      topoptions: {},
      topseries: [],
      bestSellingCredits: [],
      bestSellingVouchers: [],
      voucherRedemptionOutlet: [],
      creditRedemptionOutlet: [],
    };
  }
  componentDidMount() {
    this.loadDashboarDatas();
  }

  loadDashboarDatas() {
    var urlShringTxt =
      apiUrl + "clientpanel/dashboard?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          totalCustomer: res.data.totalCustomer,
          currentMonthTopup: showPriceValue(res.data.currentMonthTopup),
          totalTopup: showPriceValue(res.data.totalTopup),
          totalBuyVoucher: showPriceValue(res.data.totalBuyVoucher),
          totalSubscription: showPriceValue(res.data.totalSubscription),
          bestSellingCredits: res.data.bestSellingCredits,
          bestSellingVouchers: res.data.bestSellingVouchers,
          voucherRedemptionOutlet: res.data.voucherRedemptionOutlet,
          creditRedemptionOutlet: res.data.creditRedemptionOutlet,
          topoptions: {
            chart: {
              height: 450,
              type: "line",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              toolbar: {
                show: false,
              },
            },
            colors: ["#77B6EA", "#545454"],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "smooth",
            },
            title: {
              text: "Current Month Credit",
              align: "left",
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            markers: {
              size: 1,
            },
            xaxis: {
              categories: res.data.topupoptionList,
              title: {
                text: "Current Month Credit",
              },
            },
            yaxis: {
              title: {
                text: "Total Amount $",
              },
              tooltip: "$",
            },
            legend: {
              position: "top",
              horizontalAlign: "right",
              floating: true,
              offsetY: -25,
              offsetX: -5,
            },
          },
          topseries: [
            {
              name: "Topup",
              data: res.data.topupDatas,
            },
          ],
          subscribtionoptions: {
            chart: {
              height: 450,
              type: "line",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              toolbar: {
                show: false,
              },
            },
            colors: ["#77B6EA", "#545454"],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "smooth",
            },
            title: {
              text: "Current Month Subscribtion",
              align: "left",
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            markers: {
              size: 1,
            },
            xaxis: {
              categories: res.data.topupoptionList,
              title: {
                text: "Current Month Subscribtion",
              },
            },
            yaxis: {
              title: {
                text: "Total Amount $",
              },
              tooltip: "$",
            },
            legend: {
              position: "top",
              horizontalAlign: "right",
              floating: true,
              offsetY: -25,
              offsetX: -5,
            },
          },
          subscribtionseries: [
            {
              name: "Subscription",
              data: res.data.subscriptionpDatas,
            },
          ],
        });
      }
    });
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Topmenu />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold">GoepicPay wallet management</h4>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex justify-content-between">
                      <h4 className="mb-2">Sales Overview</h4>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="salesOverview"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-dots-vertical mdi-24px"></i>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="salesOverview"
                        >
                          <a className="dropdown-item" href={void 0}>
                            Refresh
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body d-flex justify-content-between flex-wrap gap-3">
                    <div className="d-flex gap-3">
                      <div className="avatar">
                        <div className="avatar-initial bg-label-primary rounded">
                          <i className="mdi mdi-account-outline mdi-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <h4 className="mb-0">{this.state.totalCustomer}</h4>
                        <small className="text-muted">Customers</small>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="avatar">
                        <div className="avatar-initial bg-label-warning rounded">
                          <i className="mdi mdi-currency-usd mdi-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <h4 className="mb-0">{this.state.currentMonthTopup}</h4>
                        <small className="text-muted">Credits This Month</small>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="avatar">
                        <div className="avatar-initial bg-label-warning rounded">
                          <i className="mdi mdi-currency-usd mdi-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <h4 className="mb-0">{this.state.totalTopup}</h4>
                        <small className="text-muted">Total Credits</small>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="avatar">
                        <div className="avatar-initial bg-label-warning rounded">
                          <i className="mdi mdi-currency-usd mdi-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <h4 className="mb-0">{this.state.totalBuyVoucher}</h4>
                        <small className="text-muted">Sell Vouchers</small>
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="avatar">
                        <div className="avatar-initial bg-label-warning rounded">
                          <i className="mdi mdi-currency-usd mdi-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <h4 className="mb-0">{this.state.totalSubscription}</h4>
                        <small className="text-muted">Subscription</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-xl-12 col-lg-12 mt-4">
                    <div className="card" id="chartdiv">
                      <div className="card-body">
                        <ReactApexChart
                          options={this.state.topoptions}
                          series={this.state.topseries}
                          type="line"
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-12 col-lg-12 mt-4">
                    <div className="card">
                      <div className="card-body">
                        <ReactApexChart
                          options={this.state.subscribtionoptions}
                          series={this.state.subscribtionseries}
                          type="line"
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 mt-4">
                    <div className="card">
                      <h5 className="card-header">Best Selling Credits</h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Plan Title</th>
                              <th>Buy Amount</th>
                              <th>Bonus Amount</th>
                              <th>Credit Amount</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {this.state.bestSellingCredits.length > 0 ? (
                              this.state.bestSellingCredits.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <i className="mdi mdi-wallet-travel mdi-20px text-danger me-3"></i>
                                        <strong>
                                          {item.wallettopup_display_name}
                                        </strong>
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.total)}
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.totalBonusCredit)}
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.totalCredit)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={4}>No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 mt-4">
                    <div className="card">
                      <h5 className="card-header">Best Selling Vouchers</h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Voucher Name</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {this.state.bestSellingVouchers.length > 0 ? (
                              this.state.bestSellingVouchers.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <i className="mdi mdi-wallet-travel mdi-20px text-danger me-3"></i>
                                        <strong>
                                          {item.buyvoucher_display_name}
                                        </strong>
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.total)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={4}>No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 mt-4">
                    <div className="card">
                      <h5 className="card-header">
                        Voucher Redemption By Outlet
                      </h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Outlet Name</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {this.state.voucherRedemptionOutlet.length > 0 ? (
                              this.state.voucherRedemptionOutlet.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <i className="mdi mdi-wallet-travel mdi-20px text-danger me-3"></i>
                                        <strong>{item.outlet_name}</strong>
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.total)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={4}>No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 mt-4">
                    <div className="card">
                      <h5 className="card-header">
                        Credit Redemption By Outlet
                      </h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Outlet</th>
                              <th>Buy Amount</th>
                              <th>Bonus Amount</th>
                              <th>Credit Amount</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {this.state.creditRedemptionOutlet.length > 0 ? (
                              this.state.creditRedemptionOutlet.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <i className="mdi mdi-wallet-travel mdi-20px text-danger me-3"></i>
                                        <strong>{item.outlet_name}</strong>
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.total)}
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.totalBonusCredit)}
                                      </td>
                                      <td className="text-end">
                                        {showPriceValue(item.totalCredit)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={4}>No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl"></div>
              </footer>

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
