/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { apiUrl, adminlimit, masterheaderconfig } from "../../Helpers/Config";
import { GET_LISTDATA } from "../../../actions";
import { decodeValue, showPriceValue } from "../../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";
var moduleLink = "/masterpanel/commission/";
class List extends Component {
  constructor(props) {
    super(props);
    var editID = this.props.match.params?.EditID || "";
    this.state = {
      editID: editID !== "" ? decodeValue(editID) : "",
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      companyList: [],
      company_id: "",
      loading: true,
    };
  }
  componentDidMount() {
    this.loadList(1);
    this.companyList();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clientyList !== this.state.clientyList) {
      this.setState({
        clientList: nextProps.clientyList,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
      });
    }
  }
  handleSelectChange(name, value) {
    var editID = "";
    if (value !== "") {
      editID = value.value;
    }
    this.setState({ [name]: value, editID: editID });
  }
  companyList() {
    var urlShringTxt = apiUrl + "company/companycontroller/companydropdown";

    axios.get(urlShringTxt, masterheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ companyList: res.data.result });
      }
    });
  }
  loadList(page) {
    var paramsD = "limit=" + adminlimit + "&offset=" + page;
    if (this.state.editID !== "") {
      paramsD += "&company_id=" + this.state.editID;
    }
    var params = {
      params: paramsD,
      url: apiUrl + "company/companycontroller/debit_list",
      type: "master",
    };
    this.props.getListData(params);
  }
  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };
  searchList() {
    this.setState({ loading: true }, function () {
      this.loadList(1);
    });
  }
  resetSearch() {
    this.setState(
      {
        loading: true,
        editID: "",
        company_id: "",
      },
      function () {
        this.loadList();
        this.props.history.push(`${moduleLink}debit-history`);
      }
    );
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"commission"} />
          <div className="layout-page">
            <Topmenu />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-9 col-md-6">
                    <h4 className="fw-bold">Debit Commission</h4>
                  </div>
                  <div className="col-lg-3 col-md-6 text-end">
                    <Link to={`${moduleLink}`}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                    <Link to={`${moduleLink}credit-history`}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect m-2"
                      >
                        View Credit
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select">
                      <Select
                        value={this.state.company_id}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_id"
                        )}
                        placeholder="Select Client"
                        isClearable={true}
                        options={this.state.companyList}
                      />
                      <label className="select-box-label">Client</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={this.searchList.bind(this)}
                    >
                      Search
                    </button>
                    <button
                      type="reset"
                      className="btn btn-label-secondary waves-effect"
                      onClick={this.resetSearch.bind(this)}
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="card">
                  <div className="table-responsive text-nowrap p-1 mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Debit Amount</th>
                          <th>Debit Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading === true ? (
                          <tr>
                            <td colSpan={7} align="center">
                              <div
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : this.state.clientList.length > 0 ? (
                          this.state.clientList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <strong>{item.company_name}</strong>
                                </td>
                                <td>
                                  {showPriceValue(item.history_debit_amount)}
                                </td>
                                <td>{item.history_created_on}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3} align="center">
                              No Record Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagenation
                    params={{
                      totalRecords: this.state.totalRecords,
                      totalPage: this.state.totalPage,
                      currentPage: this.state.currentPage,
                    }}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var clientList = Array();
  var clientListStatus = "";
  var totalPages = 0;
  var totalRecords = 0;
  if (Object.keys(state.listdata).length > 0) {
    clientListStatus = state.listdata[0].status;
    if (state.listdata[0].status === "ok") {
      clientList = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
    }
  }
  return {
    clientyList: clientList,
    totalPages: totalPages,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
